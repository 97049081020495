import React from "react"
import { Link, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import { Helmet } from "react-helmet"

const UserTemplate = ({ data }) => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Articles by {data.strapiUser.username} | Mighty Lab Blog</title>
      <link
        rel="canonical"
        href="https://www.mightylab.info/authors/{data.strapiUser.slug}"
      />
    </Helmet>
    <h1>{data.strapiUser.username}</h1>
    <ul>
      {data.strapiUser.articles.map(article => (
        <li key={article.id}>
          <h2>
            <Link to={`/articles/${article.slug}`}>{article.title}</Link>
          </h2>
          <ReactMarkdown
            source={article.summary}
            transformImageUri={uri =>
              uri.startsWith("http")
                ? uri
                : `${process.env.IMAGE_BASE_URL}${uri}`
            }
            className="indexArticle"
            escapeHtml={false}
          />
          <Link to={`/articles/${article.slug}`}>Read more</Link>
        </li>
      ))}
    </ul>
  </>
)

export default UserTemplate

export const query = graphql`
  query UserTemplate($id: String!) {
    strapiUser(id: { eq: $id }) {
      id
      username
      articles {
        id
        title
        slug
        summary
      }
    }
  }
`
